import styled from 'styled-components'
import { Textfield } from 'syf-component-library/ui/atoms'
import { colors } from 'theme'

export const TextFieldCustom = styled(Textfield)<{ $inputError?: boolean }>`
  label {
    color: ${(props) => (props.$inputError ? colors.error : colors.mediumGrey)};
  }

  input:focus ~ label {
    color: ${(props) =>
      props.$inputError ? colors.error : colors.mediumGrey} !important;
  }
`
