const common = {
  creditCardError: 'Enter a full credit card number',
  ssnPlaceholder: 'Last 4 Digits of SSN',
  ssnInvalidError: 'Last 4 digits of SSN required',
  ssnEmptyError: 'Enter the last 4 digits of SSN',
  zipCodePlaceholder: 'ZIP Code',
  zipCodeEmptyError: 'Enter a ZIP code',
  zipCodeIncompleteError: 'ZIP code must be 5 digits',
  tryAgainLater: 'Please try again later.'
}

const en = {
  labels: {
    accountEndingIn: 'Account ending in',
    activity: 'Recent Activity',
    loading: 'Finding Your Account',
    loanEndingIn: 'Loan ending in',
    waivedInterestEndingIn: 'Waived Interest Loan ending in',
    securedInstallmentEndingIn: 'Secured Installment Loan ending in'
  },
  errors: {
    littleTrouble: "Sorry, we're having a little trouble right now.",
    technicalError:
      'Our site is experiencing technical difficulties. Please try again later.',
    pageNotFound: "We can’t seem to find what you're looking for.",
    pageNotFoundImgAlt: 'page not found',
    sorryPage: 'Sorry, Something Went Wrong',
    maintenanceMessage: 'Sorry, We Are Temporarily Down For Maintenance',
    supportText: 'Please check back later or visit '
  },
  errorComponent: {
    header: 'We Apologize',
    technicalDifficulties:
      'We are currently experiencing technical difficulties and are unable to access your account. Please try again later.',
    contactCustomerService:
      'Due to a problem with your account, you may not sign in at this time. Please contact Customer Service at (844) 373-4960',
    troubleLoggingIn: "We're having trouble signing you in",
    noActiveAccounts: 'We don’t have any active accounts for you right now.',
    noActiveAccountsCustomerService:
      'If you think this is an error, contact customer support at (844) 373-4960.',
    unableToSignInAccountStatus: 'Unable to sign in due to account status',
    securedAccountCustomerService:
      'Please call Customer Service (866) 220-9432 to discuss your account or make a payment.',
    signOut: 'Sign Out',
    useButtonBelow:
      'Please use the button below to return to the homepage to sign in again',
    backToSignIn: 'Back To Sign In',
    signOutSuccessful: "You've signed out successfully",
    seeYouLater: 'See you later',
    troubleActivating: "We're having trouble activating this account",
    troubleFindingVendor: "We're having trouble finding that vendor",
    checkUrlOnCard:
      'Please check that the URL on the card’s sticker is correct or scan the QR code with your phone.',
    technicalDifficultiesActivation:
      'We are currently experiencing technical difficulties and are unable to access your account.',
    tryAgain: 'Try Again',
    generalActivationTechnicalDifficulties:
      'We are currently experiencing technical difficulties',
    tryAgainLater: common.tryAgainLater
  },
  personalGreeting: {
    morning: 'Good Morning',
    afternoon: 'Good Afternoon',
    evening: 'Good Evening',
    signOut: 'Goodbye'
  },
  footer: {
    body: 'Your credit accounts are issued by Synchrony Bank.  The Synchrony Bank Privacy Policy governs the use of your credit accounts.  The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy. The content and documents displayed may not all be provided in Spanish.',
    privacyPolicy: 'Online Privacy Policy',
    privacyPolicyLink: 'legal/privacy-policy',
    usageAgreement: 'Online Usage Agreement',
    usageAgreementLink: 'legal/terms-of-use',
    fraudProtection: 'Fraud Protection',
    fraudProtectionLink: 'legal/fraud-protection',
    accessibility: 'Accessibility',
    accessibilityLink: 'web-accessibility-statement'
  },
  loginCard: {
    title: 'Access your loan',
    signInBtn: 'Sign In',
    manageCardBtn: 'Manage A Credit Card',
    or: 'Or',
    mobilePlaceholder: 'Mobile number',
    mobileHelper: 'Same number used on your application',
    mobileError: 'Mobile number must be 10 digits',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnError: 'Last Four SSN must be 4 digits',
    ssnHelpText: 'SSN: Social Security Number',
    authErrorTitle: 'Check your information',
    authErrorDescription:
      'We don’t recognize this mobile number and SSN combination. Please try again',
    bannerSynchronyBrandName: 'Synchrony Pay Later',
    bannerBody:
      ' has enhanced the look and feel of your account servicing experience with all the same functionality.'
  },
  revolvingCardSignIn: {
    title: 'Access your credit card',
    creditCardPlaceholder: 'Credit card number',
    creditCardError: common.creditCardError,
    ssnPlaceholder: 'Last 4 Digits of SSN or ITIN',
    ssnHelpText: 'SSN: Social Security Number, ITIN: Tax ID',
    ssnError: 'Enter the last 4 digits of your SSN or ITIN',
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipError: 'Enter your ZIP Code',
    manageALoan: 'Manage A Loan',
    or: 'or'
  },
  securedInstallmentsSignIn: {
    accountNumberPlaceholder: 'Account number',
    accountNumberEmptyError: 'Enter an account number',
    accountNumberInvalidError: 'Check account number',
    signInAuthError:
      'The information you entered does not match ours. If you need help, please call customer support at (866) 220-9432.',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnHelpText: 'SSN: Social Security Number',
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeEmptyError: common.zipCodeEmptyError,
    zipCodeIncompleteError: common.zipCodeIncompleteError
  },
  universalActivation: {
    title: 'Activate your card',
    subtitle:
      'We just need a little more information to complete your activation',
    creditCardPlaceholder: 'Credit Card Number',
    creditCardHelpText: 'Enter a full credit card number',
    creditCardEmptyError: common.creditCardError,
    creditCardIncompleteError: 'Must be 16 digits long',
    securityCodePlaceholder: 'Security Code',
    securityCodeHelpText: 'Security Code on card',
    securityCodeEmptyError: "Enter card's security code",
    securityCodeIncompleteError: 'Must be 3 digits long',
    ssnPlaceholder: common.ssnPlaceholder,
    ssnHelpText: 'Enter the last 4 digits of SSN',
    ssnEmptyError: common.ssnEmptyError,
    ssnInvalidError: common.ssnInvalidError,
    zipCodePlaceholder: common.zipCodePlaceholder,
    zipCodeHelpText: '5 digit ZIP code',
    zipCodeEmptyError: common.zipCodeEmptyError,
    zipCodeIncompleteError: common.zipCodeIncompleteError,
    continueButton: 'Continue',
    cancelButton: 'Cancel',
    incorrectCredentials:
      "We're having trouble finding that account. Please check and enter your information again. If you believe this is in error, call the customer service number on the back of your card."
  },
  inactivityModal: {
    modalTitle: 'Session Timeout',
    promptTitle: 'Are you still there?',
    countdownWarning: 'Your online session will expire in',
    continueButton: "I'm still here",
    logOffButton: 'Log Off',
    seconds: 'seconds.'
  },
  mfeErrorModal: {
    modalTitle: 'Sorry, this feature is temporarily unavailable',
    content: common.tryAgainLater
  },
  buttons: {
    signOut: 'Sign Out',
    close: 'Close',
    back: 'Back'
  },
  /**
   * When the user is on an english page, the language button is going to say "Español".
   * For people using a screen reader, they need to be able to hear, in Spanish, what the button is supposed to do.
   */
  transparentHeader: {
    languageAriaLabel: 'Cambiar Idioma a Español'
  },
  header: {
    synchronyLogoImgAlt: 'Synchrony logo'
  }
}

export default en
