import { useUser } from 'hooks'
import ErrorBoundary from 'ui/molecules/ErrorBoundary'

/**
 * Component used to wrap ErrorBoundary inside of a UserContext so that it has the resetKey value available
 * ResetKey is used to re-render the ErrorBoundary as needed.
 */
const ErrorRoutingWrapper = ({ children }) => {
  const { errorBoundaryResetKey } = useUser()

  return <ErrorBoundary key={errorBoundaryResetKey}>{children}</ErrorBoundary>
}

export default ErrorRoutingWrapper
