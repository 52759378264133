import styled from 'styled-components'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'

export const MockInputContainer = styled.div`
  margin: ${spacing.small}px;
`

export const MockInputWrap = styled.input`
  padding: 2px;
  margin: 0px 8px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const ToolTipIconContainer = styled.span`
  font-size: ${fontSizes.small}px;
  margin-left: 4px;
`

export const MsgSpan = styled.span<{ $toggle?: string }>`
  color: red;
  display: ${(props) => (props.$toggle === '0' ? 'block' : 'none')};
  font-size: ${fontSizes.small}px;
`
