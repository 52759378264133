import ROUTES from 'const/routes'
import config from 'const/config'
import { authFlow, ivaShowIcon } from 'const/session'
import { SECURED_INSTALLMENTS_FLOW } from 'const/constants'
import { getAppSessionItem, setAppSessionItem } from './appSessionItem'
import loadHeadScript from './loadHeadScript'
import waitToRun from './waitToRun'
import isUniversalActivation from './authFlows/isUniversalActivation'

const { IVA_HOST } = config
const IVA_URL = `https://${IVA_HOST}.synchronycredit.com/Alme/nextit-script-manager.js`

const shouldHideIVA = (): boolean => {
  // ensures IVA doesn't load if the user is on securedInstallment auth flow
  const isSecuredInstallmentsAuthFlow =
    getAppSessionItem(authFlow) === SECURED_INSTALLMENTS_FLOW
  // need to also check for route since authFlow gets set after the actual sign in UI loads
  const isSecuredInstallmentsSignIn = window.location.pathname.includes(
    ROUTES.LOGIN_SECURED_INSTALLMENTS
  )
  const isUniversalActivationRoute = window.location.pathname.includes(
    ROUTES.UNIVERSAL_ACTIVATION
  )

  return (
    isSecuredInstallmentsAuthFlow ||
    isSecuredInstallmentsSignIn ||
    isUniversalActivationRoute ||
    isUniversalActivation()
  )
}

const handleIVALoad = () => {
  // ensures IVA loads after we get to set a currentSelectedAccount in sessionStorage
  const isGetToken = window.location.pathname.includes(ROUTES.GET_TOKEN)

  const isLoggedInDashboard = () =>
    window.location.pathname.includes(ROUTES.DASHBOARD)
  const loadIVA = () => loadHeadScript(IVA_URL, 'iva')

  if (shouldHideIVA()) {
    setAppSessionItem(ivaShowIcon, 'false')
    return
  }

  // ensure iva chat icon is visible in dom
  setAppSessionItem(ivaShowIcon, 'true')

  if (isGetToken) {
    waitToRun(isLoggedInDashboard, loadIVA)
  } else {
    loadIVA()
  }
}

export default handleIVALoad
