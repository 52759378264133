import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes, fontWeights } from 'syf-component-library/defaultTheme'
import { Body, H2 } from 'syf-component-library/ui/typography'

export const BodyTitle = styled(H2)`
  font-size: ${fontSizes.large}px;
  line-height: ${fontSizes.xxLarge}px;
  font-weight: ${fontWeights.bold};
  @media ${query.lessThanMedium} {
    font-size: ${fontSizes.medium}px;
    line-height: ${fontSizes.xLarge}px;
  }
`
export const BodyText = styled(Body)`
  font-size: ${fontSizes.root}px;
`
