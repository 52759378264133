import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import config, { ENVS, SIGNOUT_PAGE } from 'const/config'
import { logoutCorrelationId, sessionIdName } from 'const/session'
import { getAppSessionItem, setAppSessionItem } from './appSessionItem'
import { clearAccessTokenAndStorage } from './clearAccessTokenAndStorage'

/**
 * Handles the auth logout flow by revoking the current access token on the server,
 * clearing session storage of auth related values, and redirects to the signout page
 *
 * @returns {void} na
 */
const handleLogout = async (): Promise<void> => {
  const tempCorrelationId = getAppSessionItem(sessionIdName)
  setAppSessionItem(logoutCorrelationId, tempCorrelationId)
  const { APP_ENV } = config
  const isLocal = APP_ENV === ENVS.local
  // tell the auth server to revoke the current access token
  await clearAccessTokenAndStorage()
  redirectToUrl(SIGNOUT_PAGE)
  // for local dev, since localhost isn't whitelisted for redirectToUrl
  if (isLocal) {
    window.location.href = SIGNOUT_PAGE
  }
}

export default handleLogout
