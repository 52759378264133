import { getSessionAccessToken } from 'helpers/authUtils'
import clearSessionStorage from 'helpers/clearSessionStorage'
import { revokeAccessTokenOnServer } from 'helpers/revokeAccessTokenOnServer'
/**
 * clearAccessTokenAndStorage function is intended to be used at the end of the our flows to revoke the accessToken of the user, if there is one,
 * and to clear the session storage, to prevent us leaving data we don't need to be present any more
 */

const clearAccessTokenAndStorage = async (): Promise<void> => {
  if (getSessionAccessToken()) {
    await revokeAccessTokenOnServer()
    clearSessionStorage()
  }
}

export default clearAccessTokenAndStorage
