import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'syf-component-library/ui/atoms/Tooltip'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { MOCK_CARDS_LIMIT } from 'mocks/ui/const/mockInputs'
import { MOCK_NO_ACTIVE_ACCOUNTS } from 'mocks/ui/const/toggles'

import useMockToggles from 'mocks/hooks/useMockToggles/useMockToggles'
import {
  MockInputContainer,
  MockInputWrap,
  MsgSpan,
  ToolTipIconContainer
} from './MockInput.styles'
import type { MockInputProps } from './MockInput.types'

/**
 * Modifies the default values of mock data by setting items on the sessionStorage.
 * MOCK_CARDS_LIMIT: represents the intended length of the mockedAccountIds array,
 * to be read by getMockAccountsIds in order to resize the array to the set value.
 * @param MockInputProps {object} name, toolTip?, validationMsg?, defaultValue?, inputType?
 * @returns ReactElement {object}
 */
const MockInput = ({
  name,
  toolTip,
  validationMsg,
  defaultValue,
  inputType,
  onChange
}: MockInputProps): ReactElement => {
  const { mockTogglesEnabled, switchMockToggles } = useMockToggles()
  const [inputValue, setInputValue] = useState(defaultValue || '')
  const noActiveAccountsOn = mockTogglesEnabled.includes(
    MOCK_NO_ACTIVE_ACCOUNTS
  )

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    switchMockToggles(name)
    let newMockValue = e.target.value
    setInputValue(newMockValue)
    if (onChange) newMockValue = onChange(newMockValue, defaultValue)
    sessionStorage.setItem(name, newMockValue)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') return
    if (inputType === 'number' && !/[0-9]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const handleWheelEvent = (e: React.WheelEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement
    target.blur()
  }

  useEffect(() => {
    // get previous mock value if available
    if (sessionStorage.getItem(name)) {
      setInputValue(sessionStorage.getItem(name))
    }
  }, [])

  useEffect(() => {
    // if no active accounts is set, clear this one values from storage
    if (name === MOCK_CARDS_LIMIT && noActiveAccountsOn) {
      setInputValue('')
      sessionStorage.removeItem(name)
    }
  }, [noActiveAccountsOn])

  const buttonDisplayText = `${(
    name.charAt(0).toUpperCase() + name.slice(1)
  ).replaceAll('_', ' ')}`

  return (
    <MockInputContainer>
      {buttonDisplayText}
      <Tooltip content={toolTip} placement="top-start" isInteractive>
        <ToolTipIconContainer>
          <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
        </ToolTipIconContainer>
      </Tooltip>
      <MockInputWrap
        id={name}
        data-testid={name}
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onWheel={handleWheelEvent}
        type={inputType}
      />
      <MsgSpan $toggle={inputValue}>{validationMsg}</MsgSpan>
    </MockInputContainer>
  )
}

export default MockInput
