const defaultFlags = {
  name: 'Quickaccess Default Fallback Features',
  /*
   * INCREMENT THE VERSION every time you make changes - [Major].[Minor].[Patch]
   * as a reminder for SemVer versioning standards, increment:
   * MAJOR version when you make incompatible API changes - changing existing flag names or structures
   * MINOR version when you add functionality in a backward compatible manner - ie new flags
   * PATCH version when you make backward compatible bug fixes - switching flags on/off
   */
  version: '1.0.10',
  flags: {
    // allow showing the mock triggers menu
    isMockingAllowed: false,
    isSurflyEnabled: true,
    isQualtricsEnabled: true,
    isRevolvingRouteEnabled: false,
    isSecuredRouteEnabled: true,
    isUniversalRouteEnabled: true,
    isPayLaterRouteEnabled: true,
    alertBanner: {
      showAlertBanner: false,
      alertMessage: '',
      alertTitle: '',
      alertSeverity: '',
      alertLink: '',
      alertLinkText: ''
    },
    accountCard: {
      isAccountUpgradingMessageEnabled: false,
      isCBREnabled: false,
      isNotificationsEnabled: false,
      isPreDeliveryMessageEnabled: true
    },
    accountDetails: {
      isNotificationsEnabled: false
    },
    makeAPayment: {
      isOneClickAutopayEnabled: true,
      isPSCCAutopayWarningEnabled: true,
      isAvailableToSpendAnimationEnabled: true,
      isWaivedInterestEnabled: true,
      isSavingCheckingEnabled: false,
      isSecuredInstallmentEnabled: true,
      isProgressBarEnabled: true
    }
  }
}

export default defaultFlags
