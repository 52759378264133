import { ACTIVATION_BUSINESS_ERROR } from 'const/businessErrors'
import { UNIVERSAL_ACTIVATION_AUTH_ERRORS } from 'const/universalActivation'

const isErrorHandledByCardActions = (error: string) => {
  return [
    UNIVERSAL_ACTIVATION_AUTH_ERRORS.ACCOUNT_UNSUPPORTED,
    UNIVERSAL_ACTIVATION_AUTH_ERRORS.BAD_DEVICE,
    UNIVERSAL_ACTIVATION_AUTH_ERRORS.MAX_ATTEMPTS,
    ACTIVATION_BUSINESS_ERROR
  ].includes(error)
}

export default isErrorHandledByCardActions
