import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  LANGUAGE_OVERRIDE_KEY,
  SUPPORTED_LANGUAGES
} from 'strings/lang/lang.constants'
import { getAppSessionItem } from 'helpers/appSessionItem'
import {
  getSessionTokenAccounts,
  setSessionTokenAccounts
} from 'helpers/sessionTokenAccounts'
import { getSessionAccessToken } from 'helpers/authUtils'
import {
  generateUUID,
  getLanguageFromStorage as getInitialLanguageFromParamsOrStorage
} from 'syf-js-utilities'
import type { SupportedLanguage } from 'syf-js-utilities/typings/formats'
import { getMockAccountIds } from 'mocks/mockData'
import { getLocalMockDataEnabled } from 'helpers/localMockDataEnabled'
import {
  getLanguageInLocalStorage,
  setLanguageInLocalStorage,
  setLanguageInMetaTag
} from 'helpers/language'
import getCountryLanguage from 'helpers/getCountryLanguage'
import {
  setAnalyticsConfigDefaultProp,
  setSFDDLprop
} from 'helpers/analytics/SFDDLHelpers'
import { PAGE_INFO_PROPS } from 'helpers/analytics/analytics.types'

export interface UserContextProps {
  /** whether user is currently logged in and shown the logged in ui */
  isLoggedIn: boolean
  /** updates isLoggedIn state based on session storage access token */
  updateUser: () => void
  /** the user's first name */
  givenName: string
  /** sets the user's first name in state */
  setGivenName: Dispatch<SetStateAction<string>>
  currentLanguage: SupportedLanguage
  /** set the current language */
  setCurrentLanguage: Dispatch<SetStateAction<string>>
  /** handler for language change event */
  handleLanguageChange: () => void
  // array of account id strings that are v4 uuid format ie "36b8f84d-df4e-4d49-b662-bcde71a8764f"
  accountIds: string[]
  // sets a new array of account id strings into session storage and the context
  updateAccountIds: (newAccountIds: string[]) => void
  /** used to reset the main ErrorBoundary after redirects occur to prevent rendering issues  */
  errorBoundaryResetKey: string
  /** used by AppUI to handle navigation  */
  externalNavigate: string
  /** allows subscribers to request a useNavigate redirection from AppUI  */
  setErrorExternalNavigate: (route: string) => void
}

export const UserContext = createContext<UserContextProps | null>(null)

interface UserProviderProps {
  children: ReactNode
}

// ensure the user's name is always a string
const getSessionGivenName = (): string => getAppSessionItem('givenName') ?? ''
const getSessionAccountsToUse = () => {
  const isMockDataEnabledAlready = getLocalMockDataEnabled()
  return isMockDataEnabledAlready
    ? getMockAccountIds()
    : getSessionTokenAccounts()
}

const UserProvider = ({ children }: UserProviderProps): ReactElement => {
  const getIsSessionLoggedIn = (): boolean => Boolean(getSessionAccessToken())
  // whether to prefer the local storage language value if manually set or the one in the query param
  const initialLanguage =
    localStorage.getItem(LANGUAGE_OVERRIDE_KEY) === 'true'
      ? getLanguageInLocalStorage()
      : getInitialLanguageFromParamsOrStorage()

  const [isLoggedIn, setIsLoggedIn] = useState(getIsSessionLoggedIn())
  const [givenName, setGivenName] = useState(getSessionGivenName())
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage)
  const [accountIds, setAccountIds] = useState(getSessionAccountsToUse())
  const [externalNavigate, setExternalNavigate] = useState('')
  const [errorBoundaryResetKey, setErrorBoundaryResetKey] = useState(
    `${generateUUID()}`
  )

  const { EN: ENGLISH, ES: SPANISH } = SUPPORTED_LANGUAGES

  useEffect(() => {
    setLanguageInMetaTag(initialLanguage)
    // Setting Country_Language manually since it's necessary for the interceptors to work correctly
    setSFDDLprop(PAGE_INFO_PROPS.Country_Language, getCountryLanguage())
  }, [])

  // usecallback cache these updater functions so their value is stable in the context

  /** Sync logged in state to sessionStorage state based on access token */
  const updateUser = useCallback(() => {
    setIsLoggedIn(getIsSessionLoggedIn())
  }, [])

  /**
   * Set it in session storage in addition to state so it can be retrieved on page refreshes
   * since /token is only called once in auth flow
   */
  const updateAccountIds = useCallback((newAccountIds: string[]) => {
    setAccountIds(newAccountIds)
    setSessionTokenAccounts(newAccountIds)
  }, [])

  const handleLanguageChange = (): void => {
    const newLanguage: SupportedLanguage =
      currentLanguage === ENGLISH ? SPANISH : ENGLISH
    setLanguageInLocalStorage(newLanguage)
    setLanguageInMetaTag(newLanguage)
    /*
     * TEMP, this will let the app know to use the local storage language instead of
     * the one from ui-locale due to auth server automatically setting it to english
     * when no locale is set. requires updates in auth module to allow overrides so the
     * current language isn't reset every time an auth call is made.
     * We also update the default Country_Language value so when an MFE opens it is consistent with the last language selected by the user.
     */
    localStorage.setItem(LANGUAGE_OVERRIDE_KEY, 'true')
    setCurrentLanguage(newLanguage)
    setSFDDLprop(PAGE_INFO_PROPS.Country_Language, getCountryLanguage())
    setAnalyticsConfigDefaultProp(
      PAGE_INFO_PROPS.Country_Language,
      getCountryLanguage()
    )
  }

  const setErrorExternalNavigate = useCallback((route: string) => {
    setExternalNavigate(route)
    if (route) {
      setErrorBoundaryResetKey(generateUUID())
    }
  }, [])

  // data about the user and setters for that data
  const userContextValues: UserContextProps = {
    isLoggedIn,
    updateUser,
    givenName,
    setGivenName,
    currentLanguage,
    setCurrentLanguage,
    handleLanguageChange,
    accountIds,
    updateAccountIds,
    externalNavigate,
    setErrorExternalNavigate,
    errorBoundaryResetKey
  }

  // memoize the values so the context only triggers rerender on state values actually being different
  const memoizedValues = useMemo(
    () => userContextValues,
    [
      isLoggedIn,
      givenName,
      currentLanguage,
      accountIds,
      externalNavigate,
      errorBoundaryResetKey,
      setErrorExternalNavigate
    ]
  )

  return (
    <UserContext.Provider value={memoizedValues}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
