import styled from 'styled-components'
import { spacing } from 'syf-component-library/defaultTheme'
import query from 'syf-component-library/const/mediaQueries'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacing.xxlarge}px auto;
  flex-direction: column;
`
export const ActivationPageWrapper = styled.div`
  margin: ${spacing.base}px;
  width: 768px;
  @media ${query.greaterThanMedium} {
    margin: ${spacing.xxlarge}px;
  }
`
export const BannerCardContainer = styled(PageContainer)`
  justify-content: flex-start;
  flex-grow: 1;

  margin: ${spacing.base}px;

  * {
    box-sizing: border-box;
  }

  @media ${query.greaterThanMedium} {
    margin: ${spacing.xlarge}px;
  }
`

export const BannerCardInnerContainer = styled.div`
  text-align: center;
  width: 364px;
  position: relative;

  @media ${query.greaterThanMedium} {
    width: 768px;
  }
`

export default PageContainer
