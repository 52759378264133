import { IOVATION_AUTH_ERROR_CODE } from './businessErrors'

export const UNIVERSAL_ACTIVATION_AUTH_ERRORS = {
  ACCOUNT_UNSUPPORTED: 'idp.quick-authenticate.account.unsupported',
  BAD_DEVICE: IOVATION_AUTH_ERROR_CODE,
  INVALID_CLIENT: 'universal-activation.invalid.client.id',
  MAX_ATTEMPTS: 'universal-activation.max.authentication.attempts.reached'
}

export const CARD_ACTIVATION_ERRORS = {
  EXTERNAL_STATUS_CODE: '610004',
  INVALID_EXTERNAL_STATUS: '610002',
  AUTH_SHELL: '620005',
  ALREADY_ACTIVATED: '600003'
}

export const PRE_LOGIN_ACTIVATE_URL = 'v1/quick-access/cards/activate'

// Generic error to throw when an exception occurs in get-access-token page
export const UNIVERSAL_ACTIVATION_TOKEN_ERROR =
  'universal-activation.peaks.token.error'

export const TROUBLE_ACTIVATING_ACCOUNT = `trouble activating account`

export const ANALYTICS_API_ERROR_MESSAGE = 'api failure'
