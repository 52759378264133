import { HTTPDataError } from 'services/helpers/HTTPDataError'
import getFirstBusinessErrorInResponse from './getFirstBusinessErrorInResponse'
import isBusinessErrorData from './isBusinessErrorData'
import type { ErrorCase, ErrorTypes } from './getErrorCaseFromError.types'

// for type narrowing
const isError = (e: ErrorTypes): e is Error => e.name === 'Error'
const isHTTPError = (e: ErrorTypes): e is HTTPDataError =>
  e.name === 'HTTPError'
const isHTTPDataError = (e: ErrorTypes): e is HTTPDataError =>
  e.name === 'HTTPDataError'

/**
 * Returns an ErrorCase object for HTTPDataErrors. Will return the more specific business error if it exists as errorType prop.
 * Otherwise, it will return the error status code.
 * @param {object} HTTPDataError like object to extract data from
 * @returns {object} ErrorCase object with the error name, errorType, parenType and url values.
 */
const getHTTPDataError = (error: HTTPDataError): ErrorCase => {
  const { data, response } = error
  const isBusinessError = isBusinessErrorData(data)

  // return the more specific business error code if it exists
  return isBusinessError
    ? {
        name: error.name,
        errorType: getFirstBusinessErrorInResponse(data).code,
        parentType: data.code,
        url: response.url
      }
    : {
        name: error.name,
        errorType: `${response.status}`,
        parentType: null,
        url: response.url
      }
}

/**
 * Gets the most specific type of error it can find from the Error obj's data.
 * Pass in an Error like object, this function will get the most specific data it can
 * for handling the error.
 * @param error {object} an Error like object to extract data from
 * @returns {object} ErrorCase object with the error name, errorType, parenType and url values.
 */
const getErrorCaseFromError = (error: ErrorTypes): ErrorCase => {
  // HTTPData api errors may have more specific business error codes that need specific handling
  if (isHTTPDataError(error)) {
    return getHTTPDataError(error)
  }

  if (isHTTPError(error)) {
    // for generic api errors, just handle based on the http status code
    return {
      name: error.name,
      errorType: `${error.response.status}`,
      parentType: null,
      url: error.response.url
    }
  }

  /*
   * this type guard has to be last, otherwise ts thinks the error will never narrow
   * to a more specific type and the other type guard clauses think the error obj is 'never'
   * which makes accessing any properties on the error obj not type check
   */
  if (isError(error)) {
    // for general errors we just check the message passed
    return {
      name: error.name,
      errorType: error.message,
      parentType: null,
      url: null
    }
  }

  // if none of the expected error types, just coerce what we can to string
  return {
    name: null,
    errorType: `${error}`,
    parentType: null,
    url: null
  }
}

export default getErrorCaseFromError
