import ROUTES from 'const/routes'
import { HOME_PAGE } from 'const/config'
import loadHeadScript from './loadHeadScript'
import isUniversalActivation from './authFlows/isUniversalActivation'

const QUALTRICS_URL = `${HOME_PAGE}/js/qualtrics.js`

const isQualtricsEnabled = () => {
  const isUniversalActivationRoute = window.location.pathname.includes(
    ROUTES.UNIVERSAL_ACTIVATION
  )

  return !(isUniversalActivationRoute || isUniversalActivation())
}

const handleQualtricsLoad = () => {
  const loadQualtrics = () => loadHeadScript(QUALTRICS_URL, 'qualtrics')

  if (isQualtricsEnabled()) {
    loadQualtrics()
  }
}

export default handleQualtricsLoad
