import { CARD_ACTIVATION_ERRORS } from 'const/universalActivation'
import {
  ACTIVATION_BUSINESS_ERROR,
  ENCOUNTERED_BUSINESS_ERROR
} from 'const/businessErrors'
import { CardActivationErrors } from './mockCardActivation.types'

const genericErrorResponse = {
  code: ACTIVATION_BUSINESS_ERROR,
  message: ENCOUNTERED_BUSINESS_ERROR,
  trackingId: 'fbd9fc90-c43c-44f5-9511-a79d83411a08',
  transient: false
}

export const getActivationCustomErrorResponse = (customErrorCode: string) => {
  return {
    ...genericErrorResponse,
    businessErrors: [
      {
        code: customErrorCode,
        message: 'Activation custom error code'
      }
    ]
  }
}

export const cardActivationErrors: CardActivationErrors = {
  invalidExternalStatusErrorMock: {
    ...genericErrorResponse,
    businessErrors: [
      {
        code: CARD_ACTIVATION_ERRORS.INVALID_EXTERNAL_STATUS,
        message: 'External status code does not exist in Repository'
      }
    ]
  },
  externalStatusErrorMock: {
    ...genericErrorResponse,
    businessErrors: [
      {
        code: CARD_ACTIVATION_ERRORS.EXTERNAL_STATUS_CODE,
        message: 'External status code does not allow Account Activation'
      }
    ]
  },
  authShellAccountErrorMock: {
    ...genericErrorResponse,
    businessErrors: [
      {
        code: CARD_ACTIVATION_ERRORS.AUTH_SHELL,
        message: 'Auth Shell Account'
      }
    ]
  },
  alreadyActivatedStatusErrorMock: {
    ...genericErrorResponse,
    businessErrors: [
      {
        code: CARD_ACTIVATION_ERRORS.ALREADY_ACTIVATED,
        message: 'Account is already activated'
      }
    ]
  }
}

export const activationSuccessResponse = {
  productDisplayName: 'Lowes'
}
