import { PRE_LOGIN_ACTIVATE_URL } from 'const/universalActivation'
import type { ErrorCase } from './getErrorCaseFromError.types'

const getErrorTypeInHTTPDataError = (errorCase: ErrorCase): string => {
  const { url } = errorCase

  if (!url) return errorCase.errorType

  const httpErrorPathname = new URL(url).pathname

  if (httpErrorPathname === `/${PRE_LOGIN_ACTIVATE_URL}`) {
    return errorCase.parentType
  }

  return errorCase.errorType
}

export default getErrorTypeInHTTPDataError
