import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { BannerCard } from 'syf-component-library/ui/patterns'

export const PageNotFoundCard = styled(BannerCard)`
  // the image for this page is not the same height as others so we need to overwrite it
  & img {
    height: 117px;
    @media ${query.greaterThanMedium} {
      height: 236px;
    }
  }
`
