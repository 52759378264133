import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import { useUser } from 'hooks'
import getImageURL from 'helpers/getImageURL'
import { BodyText, BodyTitle } from 'ui/molecules/ErrorCard/ErrorCard.styles'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { PageNotFoundCard } from './PageNotFound.styles'
import {
  BannerCardContainer,
  BannerCardInnerContainer
} from '../Pages.subcomponents'

const PageNotFound = (): ReactElement => {
  // force footer to rerender on language change, that's stored in the user context
  useUser()
  const windowWidth = useWindowSize()
  const imageName = windowWidth.isLessThanTablet
    ? 'looking-mobile'
    : 'looking-desktop'

  return (
    <BannerCardContainer>
      <BannerCardInnerContainer>
        <PageNotFoundCard
          imageSrc={getImageURL(imageName)}
          bodyTitle={<BodyTitle>{i18n({ errors: 'sorryPage' })}</BodyTitle>}
          bodyDescription={
            <BodyText>{i18n({ errors: 'pageNotFound' })}</BodyText>
          }
        />
      </BannerCardInnerContainer>
    </BannerCardContainer>
  )
}

export default PageNotFound
