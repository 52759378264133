import { type ReactElement, useEffect, useRef } from 'react'
import useOrchestratorHelpers from 'orchestrator/OrchestratorHelpers'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Header from 'ui/molecules/Header'
import PersonalGreeting from 'ui/molecules/PersonalGreeting'
import { ErrorButtonWrap, RouteWrapper } from 'AppRoutes.styles'
import MockErrorModal from 'mocks/ui/molecules/MockErrorModal/MockErrorModal'
import { Button } from 'syf-component-library/ui/atoms'
import { AlertBanner } from 'syf-component-library/ui/patterns'
import config, { MOCK_ENABLED_APP_ENVS, MOCK_ENABLED_HOSTS } from 'const/config'
import { ROUTES_WITHOUT_GREETING } from 'const/routes'
import { createPortal } from 'react-dom'
import InactivityModal from 'ui/pages/InactivityModal'
import { Feature } from 'flagged'
import useUpdateFeatureFlags from 'hooks/useUpdateFeatureFlags'
import useUser from 'hooks/useUser'
import isUniversalActivation from 'helpers/authFlows/isUniversalActivation'
import { useFeatureFlags } from 'hooks'

const { APP_ENV } = config

const AppUI = (): ReactElement => {
  const { openMockTriggers } = useOrchestratorHelpers()
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoggedIn, externalNavigate, setErrorExternalNavigate } = useUser()
  // updates feature flags if there's an issue in initial fetch
  useUpdateFeatureFlags()
  const {
    alertBanner: {
      alertLink,
      alertLinkText,
      alertMessage,
      alertSeverity,
      alertTitle,
      showAlertBanner
    } = {}
  } = useFeatureFlags()

  useEffect(() => {
    if (externalNavigate) {
      navigate(externalNavigate)
      setErrorExternalNavigate('')
    }
  }, [externalNavigate])

  const shouldDisplayGreeting =
    isLoggedIn && !ROUTES_WITHOUT_GREETING.includes(location.pathname)

  // store a ref to the modal root element so we only need to get it once
  const { current: modalRoot } = useRef(document.getElementById('modal'))

  const isMockableHost = MOCK_ENABLED_HOSTS.includes(window.location.hostname)
  const isMockableAppEnv = MOCK_ENABLED_APP_ENVS.includes(APP_ENV)
  const showMockModal = isMockableAppEnv && isMockableHost

  return (
    <>
      {showAlertBanner && (
        <AlertBanner
          showCloseButton
          ariaLabel="alert-banner-close-button"
          alertMessage={alertMessage}
          alertTitle={alertTitle}
          alertSeverity={alertSeverity}
          alertLink={alertLink}
          alertLinkText={alertLinkText}
        />
      )}
      <Header isTransparent={!isLoggedIn} />
      {shouldDisplayGreeting && <PersonalGreeting />}
      <RouteWrapper>
        <Outlet />
      </RouteWrapper>
      {showMockModal && (
        <Feature name="isMockingAllowed">
          <ErrorButtonWrap>
            <Button
              id="mock-triggers"
              buttonType="primary"
              onClick={() => openMockTriggers()}
            >
              Open Mock Triggers
            </Button>
          </ErrorButtonWrap>
        </Feature>
      )}
      {!isUniversalActivation() && createPortal(<InactivityModal />, modalRoot)}
      <MockErrorModal />
    </>
  )
}

export default AppUI
